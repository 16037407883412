.text-area-editor {
	opacity:0;
}

#app {
	.cke_chrome {
		border-color:#ccc;
		border-radius:2px;
		overflow: hidden;
		box-shadow: none;
		width: 93%;
		/*max-width: 700px;*/
	}

	.cke_top {
		background: #eee;
		border-color:#ccc;
		box-shadow: none;
	}

	.cke_bottom {
		background: #fff;
		border-color:#ccc;
	}

	.cke_toolgroup {
		background-image: none;
		background: #fff;
		border-color:#ccc;
	}

	div[name="displayTitle"] {
		input {
			width: 81%;
		}
	}
}
