@import 'colours';

.options {
  position: absolute;
  right: 0;
  // FIXME fix this and .location-title so we don't a z-index
  z-index: 5;

  &-inner {
    display: flex;
  }

  &-group {
    i {
      font-size: 18px;
    }
  }

  button {
    text-decoration: none;
    text-align: center;
    color: @option-color;

    &:hover {
      transition: color 0.3s;
      color: @option-hover-color;
    }
    &.selected {
      transition: color 0.3s;
      color: @option-selected-color;
    }

    i {
      margin: 4px;
    }
  }
}

.options-group {
  display: flex;
  height: 70px;
  align-items: center;

  .option-value-item {
    padding-left: 15px;
    padding-right: 15px;
  
    > button {
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
