.error {
    top: 20px;
    left: 400px;
    font-size: 24px;
    position: absolute;
}

div.forgot-password {
    div {
        margin-bottom: 20px;
    }

    .forgot-login {
        margin-bottom: 0;
    }

    div.forgot-password-success {
        padding: 0 30px;
    }

}