html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, 
blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, 
kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, 
li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, 
tr, th, td, article, aside, figure, footer, header, hgroup, menu, 
nav, section, time, mark, audio, video {margin: 0; padding: 0; border: 0; 
outline: 0; font-size: 100%; vertical-align: baseline; background: transparent;}									
article, aside, figure, footer, header, hgroup, nav, section {display: block;}
img, object, embed {max-width: 100%;}
html {overflow-y: auto;}
ul li {margin-left:20px;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, 
q:after {content: ''; content: none;}
a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}
del {text-decoration: line-through;}
abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}
hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}
input, select {vertical-align: middle;}
pre {white-space: pre; white-space: pre-wrap; white-space: pre-line; word-wrap: break-word;}
input[type="radio"] {vertical-align: text-bottom;}
input[type="checkbox"] {vertical-align: bottom; *vertical-align: baseline;}
select, input, textarea {font: 99% sans-serif;}
table {font-size: inherit; font: 100%;}
a:hover, a:active {outline: none;}
small {font-size: 85%;}
strong, th {font-weight: bold;}
td, td img {vertical-align: top;} 
sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
pre, code, kbd, samp {font-family: monospace, sans-serif;}
.clickable, label, input[type=button], input[type=submit], button {cursor: pointer;}
.disabled .clickable, .disabled label, .disabled input[type=button], .disabled input[type=submit], .disabled button {cursor: default;}
button, input, select, textarea {margin: 0;}
button {width: auto; overflow: visible;}
.ie7 img {-ms-interpolation-mode: bicubic;}
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }  
.clearfix:after { clear: both; }  
.clearfix { zoom: 1; }