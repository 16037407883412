.scaffold-color-picker {
    width: 30px;
    height: 30px;
    background-image: url('assets/select.png');
    background-position: -3px -3px;
    border-radius:2px;
}

.colorpicker {
    z-index:100;
}