@import 'colours';

button {
    border:none;
    background:none;
    padding:10px 12px;
    &:hover, &:focus {
        outline:none;
    }

    &.warning-color {
        background:@button-warning-color!important;
        color:@button-warning-text-color!important;
        &:hover {
            background:@button-warning-hover-color!important;
            color:@button-warning-hover-text-color!important; 
        }
    }

    &.alert-color {
        background:@button-alert-color!important;
        color:@button-alert-text-color!important;
        &:hover {
            background:@button-alert-hover-color!important;
            color:@button-alert-hover-text-color!important; 
        }
    }

    &.disabled-color {
        background:@button-disabled-color!important;
        color:@button-disabled-text-color!important;
        &:hover {
            background:@button-disabled-color!important;
            color:@button-disabled-text-color!important; 
        }
    }
    &:disabled {
        cursor: auto;
        border-color:@button-disabled-color!important;
        background:@button-disabled-color!important;
        color:@button-disabled-text-color!important;
        &:hover {
            background:@button-disabled-color!important;
            color:@button-disabled-text-color!important; 
        }
    }
}

.btn {
    display:inline-block;
    padding:10px 12px;
    background:none;
    border:@button-border;
    border-radius:@button-radius;
    font-weight: @font-weight-bold;
    text-decoration: none;
    transition:all 0.3s;

    &:hover, &:focus {
        outline:none;
        color:@button-hover-text-color;
        border:@button-hover-border;
    }
}

.primary {
    background-color:@button-primary-color;
    color:@button-primary-text-color;
    border-color: @button-primary-color;
    i {
        color:@button-primary-text-color;
        &:hover, &:focus {
            color:@button-primary-hover-text-color;
        }
    }
    &:hover, &:focus {
        background-color:@button-primary-hover-color;
        color:@button-primary-hover-text-color;
        border-color:@button-primary-hover-color;
    }
}

.primary-hollow { 
    background-color:@button-transparent;
    color: @button-primary-color;
    border-color:@button-primary-color;
    i {
        color:@button-primary-text-color;
        &:hover, &:focus {
            color:@button-primary-hover-text-color;
        }
    }
    &:hover, &:focus {
        color: @button-primary-text-color;
        background-color: @button-primary-color;
        border-color:@button-primary-color;
    }        
}

.secondary {
    background-color:@button-secondary-color;
    color:@button-secondary-text-color;
    border-color:@button-secondary-color;
    i {
        color:@button-secondary-text-color;
        &:hover, &:focus {
            color:@button-secondary-hover-text-color;
        }
    }
    &:hover, &:focus {
        background-color:@button-secondary-hover-color;
        color:@button-secondary-hover-text-color;
        border-color:@button-secondary-hover-color;
    }
}

.secondary-hollow { 
    background-color:@button-transparent;
    color: @button-secondary-color;
    border-color:@button-secondary-color;
    i {
        color:@button-secondary-text-color;
        &:hover, &:focus {
            color:@button-secondary-hover-text-color;
        }
    }
    &:hover, &:focus {
        color: @button-secondary-text-color;
        background-color: @button-secondary-color;
        border-color:@button-secondary-color;
    }        
}

.tertiary {
    background-color:@button-tertiary-color;
    color:@button-tertiary-text-color;
    border-color:@button-tertiary-color;
    i {
        color:@button-tertiary-text-color;
        &:hover, &:focus {
            color:@button-tertiary-hover-text-color;
        }
    }
    &:hover, &:focus {
        background-color:@button-tertiary-hover-color;
        color:@button-tertiary-hover-text-color;
        border-color:@button-tertiary-hover-color;
    }
}

.tertiary-hollow { 
    background-color:@button-transparent;
    color:@button-tertiary-color;
    border-color:@button-tertiary-color;
    i {
        color:@button-tertiary-text-color;
        &:hover, &:focus {
            color:@button-tertiary-hover-text-color;
        }
    }
    &:hover, &:focus {
        color: @button-tertiary-text-color;
        background-color: @button-tertiary-color;
        border-color:@button-tertiary-color;
    }        
}    

.quaternary {
    background-color:@button-quaternary-color;
    color:@button-quaternary-text-color;
    border-color:@button-quaternary-color;
    i {
        color:@button-quaternary-text-color;
        &:hover, &:focus {
            color:@button-quaternary-hover-text-color;
        }
    }
    &:hover, &:focus {
        background-color:@button-quaternary-hover-color;
        color:@button-quaternary-hover-text-color;
        border-color:@button-quaternary-hover-color;
    }
}

.quaternary-hollow { 
    background-color:@button-transparent;
    color:@button-quaternary-color;
    border-color:@button-quaternary-color;
    i {
        color:@button-quaternary-text-color;
        &:hover, &:focus {
            color:@button-quaternary-hover-text-color;
        }
    }
    &:hover, &:focus {
        color:@button-quaternary-text-color;
        background-color:@button-quaternary-color;
        border-color:@button-quaternary-color;
    }        
}

.white-hollow {
    background-color:@button-transparent;
    color:@button-white-hollow-text-color;
    border-color:@button-white-hollow-text-color;
    &:hover, &:focus {
        color:@button-white-hollow-hover-text-color;
        background-color:@button-white-hollow-hover-color;
        border-color:@button-white-hollow-hover-color;
    }
}

/*
* Action buttons
*/
// Use these attributes to easily configure the sidebar buttons
&.action-primary {
    .secondary;
}
&.action-secondary {
    .primary-hollow;
}

.warning {
    background-color:@button-warning-color;
    color:@button-warning-text-color;
    border-color:@button-warning-color;
    &:hover, &:focus {
        background-color:@button-warning-hover-color;
        color:@button-warning-text-color;
        border-color:@button-warning-hover-color;
    }
}

.alert {
    background-color:@button-alert-color;
    color:@button-alert-text-color;
    border-color:@button-alert-color;
    &:hover, &:focus {
        background-color:@button-alert-hover-color;
        color:@button-alert-hover-text-color;
        border-color:@button-alert-hover-color;
    }
}

.btn-social {
  width: 160px;
  position: relative;
  overflow: hidden;
  text-align: left;
  padding-left: 56px;
}

.btn-social > :first-child {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 18px;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.2);
}