@import 'sharedStyles';

.pluginManagement {
  &-inner {
    margin: 15px;
    background-color: white;
    border: 1px solid #d6f2f9;
  }
}

.pluginManagement-loading {
    text-align:center;
    padding:30px;
}

.pluginManagement-upload-plugin {
  .form-container-style;
  .form-group {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}
