.pluginType-item.tb-row .row-disabled {
    color: #B5C2C6;
}

.pluginType-update {
  button {
    &.plugin-update-check {
      .secondary;
    }
    &.plugin-update-confirm {
      .primary;
    }
  }
}
