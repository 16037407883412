// Reuseable helpers classes

.display-none {
    display:none!important;
}

.visibility-hidden {
    visibility:hidden!important;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-scroll {
    overflow:hidden;
}