@import 'colours';
@import 'sharedStyles';

.frameworkImport .inner {
  .form-container-style;
  padding: 30px;

  .progress-container {
    position:relative;
    visibility:hidden;
    width:76px;
    height:10px;
    background-color: white;
    border:1px solid black;
    overflow: hidden;
    border-radius: 8px;
    margin: 10px 0;

    .progress-bar {
      width:0%;
      background-color: @primary-color;
      height:100%;
      .progress-percent {
        display: none;
      }
    }
  }

  li {
    margin-left: 0;
    list-style: none;
  }

  .title {
    padding-bottom: 30px;
    font-size: 16px;
    font-weight: 600;
  }

  form .form-group {
    border: none;
    .field-error {
      padding-bottom: 5px;
    }
  }
}
