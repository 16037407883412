@import 'colours';
@import 'fonts';

.location-title {
  font-family: @font-title;
  background-color:@location-background-color;
  border-bottom: 1px solid fade(@primary-color, 20%);
}

.location-title-inner {
  font-size: 17px;
  font-weight: @font-weight-bold;
  line-height: 20px;
  padding:25px;
  color:@location-title;
}
