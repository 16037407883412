@import 'colours';

div.tagsinput {
  border:1px solid #CCC;
  background: #FFF;
  padding:5px;
  overflow-y: auto;
  border-radius: @button-radius;

  span.tag {
    border: 1px solid @tag-title-color;
    -moz-border-radius:2px;
    -webkit-border-radius:2px;
    display: block;
    float: left;
    padding: 5px;
    text-decoration:none;
    background: @tag-background-color;
    color: @tag-title-color;
    margin-right: 5px;
    margin-bottom:5px;

    a {
      color: @tag-title-color;
      font-weight: @font-weight-bold;
      text-decoration:none;
    }
  }

  input {
    width: 80px;
    margin:0px;
    border:1px solid transparent;
    padding:5px;
    background: transparent;
    color: #000;
    outline:0px;
    margin-right:5px;
    margin-bottom:5px;
  }

  div {
    display:block;
    float: left;
  }
}

.tags_clear {
  clear: both;
  width: 100%;
  height: 0px;
}

.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  display: none;
  min-width: 160px;
  padding: 4px 0 4px 0;
  margin: 2px 0 0 0;
  list-style: none;
  background-color: #ffffff;
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
}

.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-clearfix {
  min-height: 0; /* support: IE7 */
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter:Alpha(Opacity=0); /* support: IE8 */
}

.ui-front {
  z-index: 100;
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: none;
}
.ui-menu .ui-menu {
  position: absolute;
}
.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  padding: 3px 1em 3px .4em;
  cursor: pointer;
  min-height: 0; /* support: IE7 */
  /* support: IE10, see #8844 */
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}
.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: -1px;
}

/* icon support */
.ui-menu-icons {
  position: relative;
}
.ui-menu-icons .ui-menu-item {
  padding-left: 2em;
}

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0;
}

/* right-aligned */
.ui-menu .ui-menu-icon {
  left: auto;
  right: 0;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #15a4fa;
  font-weight: bold;
  color: #fff;
}
