// apply border to only the top level fieldset
.form-container > form > div > fieldset {
  border: 1px solid #d6f2f9;
  margin: 20px;
  padding-bottom: 15px;
  max-width: 800px;
}

fieldset {
  margin: 0 0 30px 20px;
  background-color: #fff;
  form form & {
    padding-bottom: 0px;
  }
  label {
    cursor: auto;
  }
}

form .error {
  top: inherit;
  left: inherit;
  font-size: inherit;
  position: inherit;
}

.legend {
  display: block;
  legend {
    padding: 25px 0 10px 30px;
    font-size:16px;
    font-weight: @font-weight-bold;
  }
  .fieldset-object & {
    color:#3e4960;
    text-decoration: underline;
    legend {
      padding:0;
      margin-bottom:0px;
    }
  }
}

.fieldset-object .field {
  padding:18px 0px;
  &-object {
    padding:18px 20px;
  }
}

.field-object .legend {
  cursor: pointer;
}

.field {
  padding:18px 30px;
  &-object {
    padding:8px 10px;
    border:1px solid #aaa;
    border-radius:3px;
    margin-bottom:20px;
  }
  i {
    color: #3e4960;
    transition:color 0.3s;
    &:hover {
      color:#15a4fa;
    }
  }
}

.list-item {
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  word-wrap: break-word;
  &:hover {
    border-color: #696969;
  }
  span {
    display:inline-block;
    float:left;
    width:88%;
  }
  button {
    float:right;
    padding: 1px 8px;
    font-size: 140%;
  }
}

.collapsed {
  display:none;
}

.expanded {
  display:block;
}
