.scaffold-asset-preview {
	display:block;
	margin-bottom: 10px;
	transition:color 0.3s;
	i {
		font-size:60px;
	}
	&:hover {
		color:#15a4fa;
	}
}

.scaffold-asset-buttons {
	transition:color 0.3s;
	i {
		transition:inherit;
		color:inherit;
		&:hover {
			color:inherit;
		}
	}
}

.scaffold-asset-external-input-buttons {
	margin-top:10px;
}